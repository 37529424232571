/* jshint esversion: 6 */
/**
 * checks whether the screen size is mobile or desktop. In case of
 * desktop it makes the iframe 100% viewport height minus some
 * offset. In case of mobile it subtracts 100 pixels more to prevent
 * the iframe from locking in the user.
 * @param offset
 */
function resizeIframe(offset){
    var viewportHeight = $(window).height() - offset;
    var viewportWidth = $(window).width();
    if (viewportWidth > 767) {
        $('.fullheight').parent().each(function () {
            $(this).height(viewportHeight);
        });
    } else {
        $('.fullheight').parent().each(function () {
            $(this).height(viewportHeight-100);
        });
    }
}

$('iframe.fullheight').on('load', function(e) {
    resizeIframe(offset);
});


/**
 * Number of pixels needed to substract from screen size to
 * prevent scroll bar from appearing
 * @type {number}
 */

profileBarHeight = $('.top-menu').height();

const offset = (4 + profileBarHeight);

/**
 * Fires on every page
 */
$(document).ready(function(){
    $('.fa-pencil-square-o').click(function(){
        $('.form').toggle();
    });
});

/**
 * Fires on every page when browser window is resized
 */
$(window).resize(function(){
    resizeIframe(offset);
});
